'use client';

import { sendUiEvent, useUiCtxSelector, useUiStateMatches } from '@/core';
import {
  ButtonDanger,
  ButtonGroup,
  ButtonTertiary,
  Modal,
} from '@knapsack/toby';

export type ConfirmActionProps = {
  title: string;
  subtitle?: string;
  cancelLabel: string;
  confirmLabel: string;
  onCancel?: () => void;
  onConfirm: () => void;
};
const ConfirmAction = () => {
  const isConfirmActionOpened = useUiStateMatches('confirmAction.opened');
  const confirmAction = useUiCtxSelector((ui) => ui.confirmAction);

  return (
    <Modal
      opened={isConfirmActionOpened}
      onToggle={() => sendUiEvent('confirmAction.cancel')}
      testId="confirmActionModal"
      title={confirmAction?.title}
      subtitle={confirmAction?.subtitle}
      footer={
        <ButtonGroup>
          <ButtonTertiary
            label={confirmAction?.cancelLabel}
            color="subtle"
            onTrigger={() => {
              confirmAction?.onCancel?.();
              sendUiEvent('confirmAction.cancel');
            }}
          />
          <ButtonDanger
            label={confirmAction?.confirmLabel}
            onTrigger={() => {
              confirmAction?.onConfirm();
              sendUiEvent('confirmAction.confirm');
            }}
          />
        </ButtonGroup>
      }
    />
  );
};

export default ConfirmAction;

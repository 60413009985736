'use client';

/**
 * @file
 * App Wide hooks run here. This does not wrap the entire app so re-renders are isolated.
 * That's why this is a component that renders nothing.
 */

import { sendUiEvent } from '@/core';
import { useAppGqlSubscriptions } from '@/core/utils/use-app-gql-subscriptions';
import { useHashLinkHandler } from '@/core/utils/use-hash-link-handler';
import { useActiveTopNavItem } from '@/domains/navs/hooks';
import {
  useInterceptProfileIncomplete,
  useInterceptReadOnlySection,
} from '@/domains/users/intercepts';
import { useKsLocation } from '@/utils/ks-location.hooks';
import { useEffect, ReactNode } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { commonDataDogConfig } from '@/services/datadog';
import { isCypress } from '@/utils/constants';

function useUsingKeyboard() {
  useEffect(() => {
    const className = 'is-using-keyboard';
    let isUsingKeyboard = false;
    const keyboardListener = () => {
      if (isUsingKeyboard) return;
      isUsingKeyboard = true;
      document.body.classList.add(className);
    };
    const mouseListener = () => {
      if (!isUsingKeyboard) return;
      isUsingKeyboard = false;
      document.body.classList.remove(className);
    };
    document.addEventListener('keydown', keyboardListener);
    document.addEventListener('mousedown', mouseListener);
    return () => {
      document.removeEventListener('keydown', keyboardListener);
      document.removeEventListener('mousedown', mouseListener);
    };
  }, []);
}

function useRouteWatcher() {
  const location = useKsLocation();
  useEffect(() => {
    sendUiEvent({ type: 'nav.locationChanged' });
    return function cleanup() {
      sendUiEvent({ type: 'prevLocation.set', location });
    };
  }, [location]);
}

function useTopNavItemWatcher() {
  const activeTopNavItem = useActiveTopNavItem();
  useEffect(() => {
    sendUiEvent({ type: 'nav.resetExpandedNavIds' });
  }, [activeTopNavItem?.id]);
}

function useDataDogRum() {
  useEffect(() => {
    const rumEnabled = process.env.NEXT_PUBLIC_DATADOG_RUM_ENABLED === 'true';
    const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN_RUM;
    const applicationId = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID_RUM;
    if (rumEnabled && clientToken && applicationId && !isCypress()) {
      // @see: https://docs.datadoghq.com/real_user_monitoring/browser/setup/client?tab=rum#initialization-parameters
      datadogRum.init({
        ...commonDataDogConfig,
        applicationId,
        clientToken,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        telemetrySampleRate: 0,
        enableExperimentalFeatures: ['feature_flags'],

        // @see: https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm
        // beforeSend(event, _context) {
        //   return true;
        // },
      });
    }
  }, []);

  const loc = useKsLocation();
  useEffect(() => {
    datadogRum.setViewContext(loc);
  }, [loc]);
}

export const AppHooks = (): ReactNode => {
  useRouteWatcher();
  useInterceptProfileIncomplete();
  useInterceptReadOnlySection();
  useAppGqlSubscriptions();
  useUsingKeyboard();
  useHashLinkHandler();
  useTopNavItemWatcher();
  useDataDogRum();
  return null;
};

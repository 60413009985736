import { useState, useEffect } from 'react';
import { scrollMainContentToElement } from './scroll-main-content';

export function useHashLinkHandler(): void {
  const [hash, setHash] = useState<string>('');

  // Handle initial hash and hash changes
  useEffect(() => {
    setHash(window.location.hash);
    const onHashChange = () => {
      setHash(window.location.hash);
    };
    window.addEventListener('hashchange', onHashChange);
    return () => window.removeEventListener('hashchange', onHashChange);
  }, []);

  // Handle scrolling when hash changes or route changes
  useEffect(() => {
    // We need to ignore some hashes that contain specific strings from our auth provider.
    // Test for these strings and skip trying to query the DOM for them.
    const ignoreHashes = ['access_token'];

    if (hash && !ignoreHashes.includes(hash)) {
      const targetId = hash.replace('#', '');
      let attempts = 0;
      const maxAttempts = 10; // Try up to 10 times
      const initialDelay = 100; // Start with 100ms delay
      let timeoutId: number | undefined;

      const scrollToElement = () => {
        try {
          const element = document.getElementById(targetId);
          if (element) {
            scrollMainContentToElement({ element, behavior: 'auto' }); // Use 'auto' for initial load
            return true;
          }
          return false;
        } catch (error) {
          // Log error but don't throw - we want to continue retrying
          console.error('Error while attempting to scroll:', error);
          return false;
        }
      };

      // Try to scroll immediately first
      if (!scrollToElement()) {
        // If immediate attempt fails, start retry sequence
        const attemptScroll = () => {
          attempts += 1;

          try {
            if (scrollToElement()) {
              return;
            }

            if (attempts < maxAttempts) {
              // Exponential backoff with a max of 1000ms
              const delay = Math.min(initialDelay * 1.5 ** attempts, 1000);
              // Clear any existing timeout before setting a new one
              if (timeoutId) {
                window.clearTimeout(timeoutId);
              }
              timeoutId = window.setTimeout(attemptScroll, delay);
            }
          } catch (error) {
            // Clear timeout on error
            if (timeoutId) {
              window.clearTimeout(timeoutId);
            }
            console.error('Error in scroll retry sequence:', error);
          }
        };

        // Start retry sequence
        timeoutId = window.setTimeout(attemptScroll, initialDelay);
      }

      // Cleanup function to clear any pending timeouts
      return () => {
        if (timeoutId) {
          window.clearTimeout(timeoutId);
        }
      };
    }
  }, [hash]);
}

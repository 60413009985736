import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useId } from 'react';
import { assertUniqueIdInArray, isArray } from '@knapsack/utils';
import { inputSelectorWrapper, } from '../../../shared-styles/inputs.css.js';
import { withFormField } from '../../form-field/index.js';
import { TextLabel } from '../../text/index.js';
import { checkboxInput, checkboxLabel, customCheckbox, customCheckboxSvg, customCheckboxPath, checkboxesGroup, } from './checkbox.css.js';
import { Badge } from '../../badge/index.js';
export const InputCheckbox = forwardRef(({ disabled, label, onChange, parentIds = [], parentChecked = false, value = false, helperText, hiddenCount, itemType, readOnly, isValueInherited = false, testId, }, ref) => {
    const id = useId();
    return (_jsxs("span", { className: inputSelectorWrapper({ parentChecked }), style: {
            marginLeft: parentIds.length * 32,
        }, "data-testid": testId, children: [_jsx("input", { className: checkboxInput, disabled: disabled, id: id, name: label, checked: value, onChange: (event) => onChange(event.target.checked, event), type: "checkbox", ref: ref, readOnly: readOnly }), _jsxs("label", { htmlFor: id, className: customCheckbox({ isValueInherited }), children: [_jsx("svg", { className: customCheckboxSvg, viewBox: "0 0 100 100", children: _jsx("path", { className: customCheckboxPath, fill: "none", stroke: "#000", strokeWidth: "12", d: "M12.1 52.1l24.4 24.4 53-53" }) }), _jsxs(TextLabel, { className: checkboxLabel, size: "medium", children: [helperText ? (_jsxs("span", { children: [label, _jsx(TextLabel, { display: "block", size: "small", color: "subtle", children: helperText })] })) : (label), hiddenCount && value && (_jsx(Badge, { label: `${hiddenCount}${itemType ? ` ${itemType}${hiddenCount === 1 ? '' : 's'}` : ''}` }))] })] })] }));
});
export const InputCheckboxes = ({ items = [], onChange, value: checkedBoxes = [], disabled, id, onBlur, inline = false, itemType, readOnly, }) => {
    assertUniqueIdInArray({
        items,
        key: 'value',
    });
    return (_jsx("fieldset", { id: id, onBlur: onBlur, className: checkboxesGroup({
            inline,
        }), children: items.map((item) => {
            const parentValues = new Set(items.flatMap((i) => (isArray(i.parentIds) ? i.parentIds : [])));
            const children = items
                .filter((i) => isArray(i.parentIds) && i.parentIds.includes(item.value))
                .filter((i) => !parentValues.has(i.value)) // Exclude parents
                .map((i) => i.value);
            const parents = item.parentIds || [];
            const parentChecked = parents.some((parent) => checkedBoxes.includes(parent));
            return (_jsx(InputCheckbox, { label: item.label, disabled: disabled || item.disabled, helperText: item.helperText, value: checkedBoxes.includes(item.value), parentIds: item.parentIds, parentChecked: parentChecked, hiddenCount: children.length > 0 ? children.length : undefined, itemType: itemType, readOnly: readOnly, onChange: (checked, event) => {
                    /**
                     * If the item has children, uncheck them all
                     * If the item has parents, uncheck them all
                     */
                    const values = checkedBoxes
                        .filter((box) => !children.includes(box))
                        .filter((box) => !parents.includes(box));
                    if (checked) {
                        onChange([...values, item.value], event);
                    }
                    else {
                        onChange(values.filter((box) => box !== item.value), event);
                    }
                } }, item.value));
        }) }));
};
export const FieldCheckbox = withFormField(InputCheckbox);
export const FieldCheckboxes = withFormField(InputCheckboxes);
